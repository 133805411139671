<template>
  <div class="container">
    <ObjectItem
        class="house1"
        :images="[
          '../assets/objects/houses/house_1.png',
        ]"
        name="Многоквартирный ЖК с нежилыми помещениями"
        address="г. Караганда, ул. Степной, 3"
    />
    <ObjectItem
        class="house2"
        :images="[
         '../assets/objects/houses/house_2.png',
        ]"
        name="Многоквартирный ЖК с нежилыми помещениями"
        address="г. Караганда, пр-т Строителей 33/4"
    />
    <ObjectItem
        class="house3"
        :images="[
          '../assets/objects/houses/house_3/1.png',
          '../assets/objects/houses/house_3/2.png',
          '../assets/objects/houses/house_3/3.png',
        ]"
        name="ЖК «Комфортный город»"
        desc="г. Караганда, пр-т Шахтеров, 52Б"
    />
    <ObjectItem
        class="house4"
        :images="[
          '../assets/objects/houses/house_4.png',
        ]"
        name="ЖК «Керемет»"
        address="г. Караганда, ул. Амишова, 21"
    />
    <ObjectItem
        class="house5"
        :images="[
         '../assets/objects/houses/house_5.png',
        ]"
        name="Многоквартирный ЖК"
        address="г. Караганда, ул. Ержанова"
    />
    <ObjectItem
        class="complex"
        :images="[
          '../assets/objects/houses/complex/1.png',
          '../assets/objects/houses/complex/2.png',
          '../assets/objects/houses/complex/3.png',
        ]"
        name="Многоквартирный жилой комплекс"
        address="г. Астана"
    />
  </div>
</template>

<script>
import ObjectItem from './ObjectItem'

export default {
  name: "SocialObjects",
  components: {
    ObjectItem,
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-areas: "house1 house2"
                       "house3 house4"
                       "house3 house5"
                       "complex complex";
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
}

.house1 {
  grid-area: house1;
  margin-bottom: 48px;
}

.house2 {
  grid-area: house2;
  margin-bottom: 48px;
}

.house3 {
  grid-area: house3;
  margin-bottom: 48px;
}

.house4 {
  grid-area: house4;
  margin-bottom: 32px;
}

.house5 {
  grid-area: house5;
  margin-bottom: 75px;
}

.complex {
  grid-area: complex;
}

@media screen and (max-width: 735px) {
  .container {
    display: grid;
    grid-template-areas: "house1 house1"
                       "house2 house2"
                       "house3 house3"
                       "house4 house4"
                       "house5 house5"
                        "complex complex";
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 36px;
  }

  .house1 {
    margin-bottom: 0;
  }
  .house2 {
    margin-bottom: 0;
  }
  .house3 {
    margin-bottom: 0;
  }
  .house4 {
    margin-bottom: 0;
  }
  .house5 {
    margin-bottom: 0;
  }
}

</style>
