<template>
  <Carousel>
    <Slide
        v-for="image of images"
        :key="image"
    >
      <div class="image-container">
        <img class="image" :src="image" :alt="name">

        <div class="object-data">
          <span class="object-name">
            {{ name }}
          </span>

          <div v-if="address" class="object-address">
            <div class="object-address-icon"></div>
            {{ address }}
          </div>

          <span class="object-desc">
            {{ desc }}
          </span>
        </div>
      </div>
    </Slide>

    <template #addons v-if="images.length > 1">
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
  name: "ObjectItem",
  props: {
    images: Array,
    name: String,
    address: String,
    desc: String
  },
  components: {
    Carousel,
    Slide,
    Pagination
  }
}
</script>

<style>
.image-container {
  position: relative;
}

.object-data {
  position: absolute;
  top: 0;
  right: 0;

  visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  height: 100%;
  padding: 4px;

  background: rgba(21, 21, 21, 0.95);
  border-radius: 16px;

  z-index: 1;
}

.image-container:hover .object-data {
  visibility: visible;
}

.object-name,
.object-desc {
  text-align: center;
  font-weight: 400;
  font-size: 28px;
  color: #FFFFFF;
}

.object-desc {
  font-size: 17px;
}

.object-address {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;

  font-size: 17px;
  color: #FFFFFF;
}
.object-address-icon {
  height: 24px;
  width: 24px;
  background: url("../assets/ObjectAddress.svg");
}

@media screen and (max-width: 735px) {
  .object-data {
    gap: 8px;
  }
  .object-name {
    font-size: 18px;
  }
  .object-address, .object-desc {
    font-size: 12px;
  }
}

.image {
  display: block;
  height: 100%;
  width: 100%;
}

.image-alone {
  box-sizing: border-box;
}

.pagination-placeholder {
  height: 35px;
}

.carousel__pagination {
  padding: 0;
  margin: 16px 0 0;

  --vc-pgn-height: 8px;
  --vc-pgn-width: 94px;
  --vc-pgn-active-color: #05415B;
  --vc-pgn-background-color: #ECECEC;
  --vc-pgn-margin: 0 8px;
}

.carousel__pagination-button {
  border-radius: 2px;
}

</style>