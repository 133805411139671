<template>
  <div class="tabs">
    <button
        :class="getClass(0)"
        @click="update(0)"
    >
      Социальные объекты
    </button>
    <button
        :class="getClass(1)"
        @click="update(1)"
    >
      Многоквартирные жилые комплексы
    </button>
    <button
        :class="getClass(2)"
        @click="update(2)"
    >
      Промышленные объекты
    </button>
  </div>
</template>

<script>
export default {
  name: 'ObjectTabs',
  emits: ['update:modelValue'],
  props: {
    modelValue: Number,
  },
  methods: {
    update(value) {
      this.$emit('update:modelValue', value)
    },
    getClass(value) {
      return {
        tab: true,
        activeTab: value === this.modelValue,
      }
    }
  }
}
</script>

<style scoped>
.tabs {
  display: flex;
  border: 1px solid #D7D7DA;
  border-radius: 12px;
  padding: 8px;
  overflow: auto;
  max-width: fit-content;
}

.tab {
  padding: 18px 24px;

  font-size: 17px;
  color: #05415B;
  font-weight: 600;

  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 840px) {
  .tab {
    font-size: 14px;
    font-weight: 600;
    padding: 12px 20px;
  }
}

.activeTab {
  background: #05415B;
  color: #FFFFFF;
}


</style>