<template>
  <notifications position="bottom right" classes="my-custom-class" />

  <div class="form-wrapper">
    <form class="contact-form" @submit="submit">
      <input
          name="companyName"
          type="text"
          placeholder="Имя / название компании"
      >
      <input
          name="email"
          type="email"
          placeholder="Почта"
      >
      <textarea
          name="description"
          placeholder="Что нужно будет сделать"
      />
      <button>
        Отправить
      </button>
    </form>

    <Carousel class="carousel" :mouseDrag="false" :touchDrag="false">
      <Slide key="map">
        <div id="map-wrapper">
        </div>
      </Slide>
      <Slide key="address1">
        <div>
          <img class="addressImage" src="../assets/address_pics/1.png" alt="Подход к зданию">
        </div>
      </Slide>
      <Slide key="address1">
        <div>
          <img class="addressImage" src="../assets/address_pics/2.png" alt="Вход">
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import ymaps from 'ymaps';
import { notify } from "@kyvg/vue3-notification";


export default {
  name: "ContactsForm",
  components: {
    Carousel,
    Slide,
    Pagination
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap()
    })
  },
  methods: {
    initMap() {
      // const apiKey = '2a3f1a48-92ea-4b2a-b08c-a265212e4e18'
      const coords = [49.807584, 73.090743]

      ymaps
          .load()
          .then(maps => {
            const map = new maps.Map('map-wrapper', {
              center: coords,
              zoom: 14,
            })

            map.geoObjects.add(new maps.Placemark(coords, null, {
              preset: 'islands#greenDotIconWithCaption',
              iconColor: '#05415B'
            }))
          })
    },
    submit(ev) {
      ev.preventDefault()
      const formData = new FormData(ev.target);
      const inputObject = Object.fromEntries(formData);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(inputObject),
      };

      fetch("mail.php", requestOptions)
          .then(response => response.text())
          .then(() => {
            ev.target.reset()
            notify({
              text: 'Спасибо за интерес! С вами обязательно свяжутся в ближайшее время.',
            })
          })
          .catch(() => {
            notify({
              text: 'Произошла ошибка',
            })
          });
    }
  }
}
</script>

<style>
.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 39px;
}

.contact-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  box-sizing: border-box;
  border: 1px solid #D3D3D5;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  padding: 20px 16px;
}

.contact-form input {
  margin-bottom: 24px;
  min-width: 474px;
  height: 60px;
}

@media screen and (max-width: 474px) {
  .contact-form input {
    min-width: 350px;
  }
}

.contact-form textarea {
  margin-bottom: 40px;
  height: 150px;
  resize: vertical;
}

.contact-form button {
  box-sizing: border-box;
  height: 60px;
  width: 141px;
  padding: 18px 24px;

  background: #05415B;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;

  transition: background-color 300ms ease-in-out;
}

.contact-form button:hover {
  background: #032e3f;
}


.carousel {
  flex: 1;
}

#map-wrapper {
  width: 100%;
  height: 418px;
}

.addressImage {
  display: block;
  height: 100%;
  width: 100%;
}

.pagination-placeholder {
  height: 35px;
}

.carousel__pagination {
  padding: 0;
  margin: 16px 0 0;

  --vc-pgn-height: 8px;
  --vc-pgn-width: 94px;
  --vc-pgn-active-color: #05415B;
  --vc-pgn-background-color: #ECECEC;
  --vc-pgn-margin: 0 8px;
}

.carousel__pagination-button {
  border-radius: 2px;
}
</style>