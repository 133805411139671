<template>
  <ObjectTabs v-model="tab" class="tabs" />
  <SocialObjects v-if="tab === 0" />
  <HousesObjects v-else-if="tab === 1" />
  <PromObjects v-else-if="tab === 2" />
</template>

<script>
import SocialObjects from "@/components/SocialObjects";
import ObjectTabs from "@/components/ObjectTabs";
import HousesObjects from "@/components/HousesObjects";
import PromObjects from "@/components/PromObjects";

import './main.css'

export default {
  name: 'App',
  components: {ObjectTabs, SocialObjects, PromObjects, HousesObjects},
  data() {
    return {
      tab: 0,
    }
  }
}
</script>

<style scoped>
.tabs {
  margin-top: 40px;
  margin-bottom: 60px;
}
@media screen and (max-width: 840px) {
  .tabs {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
</style>
