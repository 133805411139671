<template>
  <div class="container">
    <ObjectItem
      class="shahter"
      :images="[
          '../assets/objects/social/shahter/1.png',
          '../assets/objects/social/shahter/2.png',
          '../assets/objects/social/shahter/3.png',
      ]"
      name="Футбольный мане «Шахтер» на 3000 мест."
      address="г. Караганда, ул. Казахстанская, 1"
    />
    <ObjectItem
        class="det-sad"
        :images="[
          '../assets/objects/social/det_sad/1.png',
          '../assets/objects/social/det_sad/2.png',
          '../assets/objects/social/det_sad/3.png',
      ]"
        name="Детский сад"
    />
    <ObjectItem
        class="ggg"
        :images="[
          '../assets/objects/social/ggg/1.png',
          '../assets/objects/social/ggg/2.png',
          '../assets/objects/social/ggg/3.png',
      ]"
        name="Многофункциональный физкультурно-оздоровительный комплекс имени Г. Головкина (ФОК «GGG»)"
    />
    <ObjectItem
        class="abk"
        :images="[
          '../assets/objects/social/abk/1.png',
          '../assets/objects/social/abk/2.png',
          '../assets/objects/social/abk/3.png',
      ]"
        name="АБК на 200 человек с боксами"
        address="г. Караганда, 30 мкр-н"
    />
    <ObjectItem
        class="abk"
        :images="[
          '../assets/objects/social/abk/1.png',
          '../assets/objects/social/abk/2.png',
          '../assets/objects/social/abk/3.png',
      ]"
        name="АБК на 200 человек с боксами"
        address="г. Караганда, 30 мкр-н"
    />
    <ObjectItem
        class="business-center"
        :images="[
          '../assets/objects/social/business_center/1.png',
          '../assets/objects/social/business_center/2.png',
          '../assets/objects/social/business_center/3.png',
      ]"
        name="Бизнес-центр"
        address="г. Караганда"
    />
    <ObjectItem
        class="sport-little"
        :images="[
          '../assets/objects/social/sport_little/1.png',
          '../assets/objects/social/sport_little/2.png',
          '../assets/objects/social/sport_little/3.png',
      ]"
        name="Спортивный комплекс им. Каната Байшулакова"
        address="г. Караганда"
    />
    <ObjectItem
        class="city-mall"
        :images="[
          '../assets/objects/social/city_mall/1.png',
          '../assets/objects/social/city_mall/2.png',
          '../assets/objects/social/city_mall/3.png',
      ]"
        name="ТРЦ «City Mall»"
        address="г. Караганда"
    />
  </div>
</template>

<script>
import ObjectItem from './ObjectItem'

export default {
  name: "SocialObjects",
  components: {
    ObjectItem,
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-areas: "shahter det_sad"
                       "ggg ggg"
                       "abk business_center"
                       "sport_little city_mall";
  grid-column-gap: 32px;
  grid-row-gap: 48px;
}

@media screen and (max-width: 735px) {
  .container {
    display: grid;
    grid-template-areas: "shahter shahter"
                       "det_sad det_sad"
                       "ggg ggg"
                       "abk abk"
                       "business_center business_center"
                        "sport_little sport_little"
                        "city_mall city_mall";
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 36px;
  }
}

.shahter {
  grid-area: shahter;
}

.det-sad {
  grid-area: det_sad;
}

.ggg {
  grid-area: ggg;
}

.abk {
  grid-area: abk;
}

.business-center {
  grid-area: business_center;
}

.sport-little {
  grid-area: sport_little;
}

.city-mall {
  grid-area: city_mall;
}
</style>