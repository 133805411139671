<template>
  <div class="container">
    <ObjectItem
        class="fl-smidth"
        :images="[
          '../assets/objects/prom/fl_smidth/1.png',
          '../assets/objects/prom/fl_smidth/2.png',
          '../assets/objects/prom/fl_smidth/3.png',
      ]"
        name="FLSmidth"
        desc="Глобальный поставщик устойчивой производительности для цементной и горнодобывающей промышленности."
    />
    <ObjectItem
        class="kae"
        :images="[
          '../assets/objects/prom/kae/1.png',
          // '../assets/objects/prom/kae/2.png',
          '../assets/objects/prom/kae/3.png',
          '../assets/objects/prom/kae/4.png',
          // '../assets/objects/prom/kae/5.png',
      ]"
        name="Казахстан Инжиниринг"
        address="Нур-Султан, ул. Керей, Жанибек хандар 12А"
    />
    <ObjectItem
        class="beck-u"
        :images="[
          '../assets/objects/prom/beck_u/1.png',
          '../assets/objects/prom/beck_u/2.png',
          '../assets/objects/prom/beck_u/3.png',
      ]"
        name="Beck u.Kaltheuner Feuerfeste Erzeugnisse GmbH & Co.KG "
        desc="Компания производит предварительно сформованные огнеупорные блоки для сложных печных систем."
    />
    <ObjectItem
        class="arktika"
        :images="[
          '../assets/objects/prom/arktika/1.png',
          '../assets/objects/prom/arktika/2.png',
          '../assets/objects/prom/arktika/3.png',
      ]"
        name="Первый в Казахстане мини-завод по выпуску зимнего дизельного топлива"
        address="п. Шидерты"
    />
    <ObjectItem
        class="kotel"
        :images="[
          '../assets/objects/prom/kotel/1.png',
          '../assets/objects/prom/kotel/2.png',
          '../assets/objects/prom/kotel/3.png',
      ]"
        name="Строительство котельной и тепловых сетей"
        address="п. Шахан"
        desc="Котельной предназначено для производства теплоносителя. Здание оборудовано электрическими талями, 3-мя водогрейными котлами с технологией сжигания топлива в кипящем слое на твердом топливе и вспомогательным оборудованием."
    />
    <ObjectItem
        class="shveinaya-fabrica"
        :images="[
          '../assets/objects/prom/shveinaya_fabrica/1.png',
          '../assets/objects/prom/shveinaya_fabrica/2.png',
          '../assets/objects/prom/shveinaya_fabrica/3.png',
      ]"
        name="Фабрика по производству ткани “SAMRUK TEKSTIL”"
        address="г. Шымкент"
    />
    <ObjectItem
        class="zavod"
        :images="[
          '../assets/objects/prom/zavod/1.png',
          '../assets/objects/prom/zavod/2.png',
          '../assets/objects/prom/zavod/3.png',
      ]"
        name="Завод по производству запасных частей и колесных пар для ЖД транспрота"
        address="с. Доскей"
    />
  </div>
</template>

<script>
import ObjectItem from './ObjectItem'

export default {
  name: "SocialObjects",
  components: {
    ObjectItem,
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-areas: "flsmidth kae"
                       "becku arktika"
                       "kotel kotel"
                       "shveinayafabrica zavod";
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 48px;
}

@media screen and (max-width: 735px) {
  .container {
    display: grid;
    grid-template-areas: "flsmidth flsmidth"
                       "kae kae"
                       "becku becku"
                       "arktika arktika"
                       "kotel kotel"
                       "shveinayafabrica shveinayafabrica"
                        "zavod zavod";
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 36px;
  }
}

.fl-smidth {
  grid-area: flsmidth;
}

.kae {
  grid-area: kae;
}

.beck-u {
  grid-area: becku;
}

.arktika {
  grid-area: arktika;
}

.kotel {
  grid-area: kotel;
}

.shveinaya-fabrica {
  grid-area: shveinayafabrica;
}

.zavod {
  grid-area: zavod;
}
</style>